<template lang="html">
  <div
    class="tg_timegraph_container"
    :id="unique_identifier"
    :ref="unique_identifier"
  >
    <Modal v-if="save_graph_settings_as_modal" :title="$txt.graph_settings">
      <LabeledInput
        :label="$txt.name"
        v-model="save_graph_settings_as_name"
      />
      <br />
      <DropDown
        :title="$txt.graph_settings"
        v-model="selected_save_graph_settings_as"
        :opts="available_graph_settings"
        :disabled="false"
      />
      <template v-slot:footer>
        <Btn
          v-if="$app.sltd.project.dto.canDelete"
          :title="$txt.delete_graph_setting"
          @clicked="
            delete_graph_setting(selected_save_graph_settings_as);
            "
          :disabled="(selected_save_graph_settings_as == null)  ? true : null"
          style="float: left"
        />
        <Btn 
          :title="$txt.cancel" 
          class="hol_btn"
          @clicked="save_graph_settings_as_modal = false" 
        />
        <Btn
          :title="$txt.save_graph_settings"
          @clicked="save_graph_settings_as();
            save_graph_settings_as_modal = false;
            "
          :disabled="(save_graph_settings_as_name.length == 0)  ? true : null"
        />
      </template>

    </Modal>

    <Modal v-if="settings_modal" :title="$txt.graph_settings">
      <LabeledCheckbox
        :label="$txt.use_custom_size"
        v-model="use_custom_size"
      />
      <NumericInput
        :disabled="(!use_custom_size) ? true : null"
        :label="$txt.width_px"
        v-model="custom_width"
      />
      <NumericInput
        :disabled="(!use_custom_size) ? true : null"
        :label="$txt.height_px"
        v-model="custom_height"
      />
      <br />
      <DropDown
        :title="$txt.download_file_format"
        :tight="true"
        v-model="download_format"
        :opts="[{ id: 'png' }, { id: 'svg' }]"
      />

      <template v-slot:footer>
        <Btn 
          :title="$txt.cancel" 
          class="hol_btn"
          @clicked="settings_modal = false" 
        />
        <Btn
          :title="$txt.submit"
          @clicked="
            settings_modal = false;
            resize();
            emitDiagramSettings();
          "
        />
       
      </template>
    </Modal>

    <Modal v-if="channel_serie_options_modal" :title="channel_serie_options.title">
      <LabeledInput 
        :label="$txt.y_axis_label" 
        v-model="channel_serie_options.yLabel" 
        :width="300"
      />
      <br />

      <LabeledCheckbox 
        :label="$txt.override_y_axis_scale" 
        v-model="channel_serie_options.override_y_axis_scale" 
      />
      <br />
      <NumericInput
        :label="$txt.y_axis_scale_min"
        v-model="channel_serie_options.override_y_axis_scale_min"
        :disabled = "(!channel_serie_options.override_y_axis_scale) ? true : null"
      />
      <NumericInput
        :label="$txt.y_axis_scale_max"
        v-model="channel_serie_options.override_y_axis_scale_max"      
        :disabled = "(!channel_serie_options.override_y_axis_scale) ? true : null"
      />
      <br /> 
      <LabeledCheckbox 
        :label="$txt.draw_line_at_0" 
        v-model="channel_serie_options.zero_line" 
      />
      <LabeledCheckbox 
        :label="$txt.draw_grid" 
        v-model="channel_serie_options.grid" 
      />
      <br />
      <LabeledCheckbox 
        :label="$txt.draw_line_at" 
        v-model="channel_serie_options.draw_line_at" 
      />
      <NumericInput
        label=""
        v-model="channel_serie_options.draw_line_at_value"
        style = "width:100px"
        :disabled = "(!channel_serie_options.draw_line_at) ? true : null"
      />
      <br />
      <EnumDropDown
        :title="$txt.graph_pos"
        :enum_to_choose_from="$enum.graph_pos"
        :disabled="false"
        v-model="channel_serie_options.yPos"
      />    
      <!-- <EnumDropDown
        :title="$txt.graph_type"
        :enum_to_choose_from="$enum.graph_type"
        :disabled="false"
        v-model="channel_serie_options.graph_type"
      />   -->
      <template v-slot:footer>
        <Btn 
          :title="$txt.toggle_select_y_axis" 
          class="hol_btn"
          @clicked="toggleSelectYAxisFromChannel(channel_serie)"
          style="float: left" 
        />
        <Btn 
          :title="$txt.cancel" 
          class="hol_btn"
          @clicked="channel_serie_options_modal = false" 
        />
        <Btn
          :title="$txt.submit"
          @clicked="
            channel_serie_options_modal = false;
            submit_channel_serie_options();
            resize();
          "
        />
      </template>
    </Modal>

    <Modal v-if="axis_options_modal" :title="axis_options.title">

      <LabeledInput 
        :label="$txt.y_axis_label" 
        v-model="axis_options.yLabel" 
        :width="300"
      />
      <br />

      <LabeledCheckbox 
        :label="$txt.override_y_axis_scale" 
        v-model="axis_options.override_y_axis_scale" 
      />
      <br />
       <NumericInput
        :label="$txt.y_axis_scale_min"
        v-model="axis_options.override_y_axis_scale_min"
        :disabled = "(!axis_options.override_y_axis_scale) ? true : null"
      />
      <NumericInput
        :label="$txt.y_axis_scale_max"
        v-model="axis_options.override_y_axis_scale_max"      
        :disabled = "(!axis_options.override_y_axis_scale) ? true : null"
      />
      <br />

      <LabeledCheckbox 
        :label="$txt.draw_line_at_0" 
        v-model="axis_options.zero_line" 
      />
      <LabeledCheckbox 
        :label="$txt.draw_grid" 
        v-model="axis_options.grid" 
      />
      <LabeledCheckbox 
        :label="$txt.draw_line_at" 
        v-model="axis_options.draw_line_at" 
      />
      <NumericInput
        label=""
        v-model="axis_options.draw_line_at_value"
        style = "width:100px"
        :disabled="(!axis_options.draw_line_at_value) ? true : null"
      />
      <br />
      <LabeledInput 
        :label="$txt.graph_description" 
        v-model="new_graph_description" 
        :width="300"
      />
    
      <template v-slot:footer>
        <Btn 
          :title="$txt.cancel" 
          class="hol_btn"
          @clicked="axis_options_modal = false" 
        />
        <Btn
          :title="$txt.submit"
          @clicked="
            axis_options_modal = false;
            submit_axis_options();
            resize();
          "
        />
      </template>
    </Modal>

    <Modal v-if="graph_description_modal" :title="$txt.graph_settings">

      <LabeledInput 
        :label="$txt.graph_description" 
        v-model="new_graph_description" 
        :width="300"
      />
    
      <template v-slot:footer>
        <Btn 
          :title="$txt.cancel" 
          class="hol_btn"
          @clicked="graph_description_modal = false" 
        />

        <Btn
          :title="$txt.submit"
          @clicked="
            axis_options_modal = false;
            submit_graph_description();
            resize();
          "
        />
        
      </template>
    </Modal>

    <CompactPanel style = "height:40px">
      <CheckBox
        v-model="show_channel_control_panel"
        :title="$txt.hint_show_channel_control_panel"
        @change="emitDiagramSettings"
      />
      {{ $txt.show_channel_controls}}
    
      <CheckBox
        v-model="show_legend"
        :title="$txt.hint_show_legend"
        @change="emitDiagramSettings"
      />
      {{ $txt.show_legend }}
      <CheckBox
        v-if="has_hold_values"
        @click="show_hold_values_in_graph()"
        v-model="showHoldValues"
      />
      {{ has_hold_values ? $txt.show_hold_values:'' }}
      <DropDown
        title=""
        v-model="selected_graph_setting"
        :opts="available_graph_settings"
        :disabled="false"
        style="float: right"
        @change="disable_settings_loaded_from_report"
      >
      </DropDown>
      <Btn
        v-if="$app.sltd.project.dto.canSetup"
        :title="$txt.save_graph_settings"
        class="hol_btn"
        @clicked="save_graph_settings()"
         style="float: right"
        :disabled = "(!any_graph_setting_selected) ? true : null"
      />
      <Btn
        v-if="$app.sltd.project.dto.canSetup"
        :title="$txt.save_graph_settings_as"
        class="hol_btn"
        @clicked="prepare_to_save_graph_settings_as()"
         style="float: right"
      />
      <Btn
        :title="$txt.reset_graph_settings"
        class="hol_btn"
        @clicked="reset_graph_settings(true, true)"
        style="float: right"
      /> 
      <Btn
        v-if="diagram && diagram.diagramSettings && diagramSettingsStored"
        :title="'reload report settings'"
        class="hol_btn"
        @clicked="
          reload_stored_settings();
          load_graph_settings_from_saved_report_diagram();
        "
        style="float: right"
      />
      <Btn
        v-if="has_hold_values"
        :title="$txt.hold_values"
        @clicked="hold_values(), save_hold_values_to_localStorage()"
        style="float: none; left: 200px"
      />
      <Btn
        v-if="has_fill_channels"
        :title="fill_channels_title"
        @clicked="fill_channels()"
        style="float: none; left: 200px"
      />
      <Btn
        v-if="has_select_nodes && !select_mode"
        :title="select_nodes_text"
        @clicked="select_nodes()"
        style="float: none; left: 200px"
      />
      <Btn
        v-if="has_select_nodes && select_mode"
        :title="done_select_nodes_text"
        @clicked="done_select_nodes()"
        style="float: none; left: 200px"
      />
    </CompactPanel>

    <div class="tg_timegraph_holder" id = "my_timegraph" ref="my_timegraph">
      <div class="tg_select_channel_column" id="my_channel_column" v-if="show_channel_control_panel" style="width: 450px">
        <p class="wm_control_label">{{ $txt.channels }}</p>
        <table>
          <tbody>
            <tr>
              <td style="width: 20px">
                <input
                  class="wm_checkbox"
                  type="checkbox"
                  :checked="this.graph_buffer ? this.graph_buffer.hidden_series.length == 0 : false"
                  v-on:input="select_deselect_all()"
                  @change="emitDiagramSettings"
                  title="Hide/Show all channels in graph"
                  />
              </td>
              <td>
                {{ select_deselect_message }}
              </td>
              <td>
                <span v-if="showHoldValues">
                  {{ $txt.held_values }}
                </span>
              </td>
            </tr>
            <div v-if="showHoldValues">
              <tr v-for="s in channel_series" :key="s.id" >
                <td>
                  <input
                    class="wm_checkbox"
                    type="checkbox"
                    v-on:input="toggle_hidden(s)"
                    :checked="!is_hidden(s)"
                    :title="$txt.hint_show_channel_in_graph"
                  />
                  <a
                    class="wm_icon_btn"
                    :title="$txt.open_settings_for_serie"
                    @click="show_channel_serie_options(s)"
                    :style="{color:'gray'}"
                  >
                    <svg-icon size="16" viewbox="0 0 18 18" :path="settingsIcon" />
                  </a> 
                  <!--<IconBtn
                    size="15"
                    :icon="$icon.graph_settings"
                    :tooltip="$txt.open_settings_for_serie"
                    @click="show_channel_serie_options(s)"
                  /> -->
                  <span 
                    class="wm_table_colored_box"
                    draggable="true"
                    @dragstart="startDrag($event, s)"
                    :style="{
                    backgroundColor: is_hidden(s) ? 'gray' : s.color
                    }"
                    
                    @click="toggleSelectYAxisFromChannel(s)"
                  >
                    {{s.name}}
                  </span>
                </td>
                <td style="text-align: right" id="my_held_values">
                  <span v-if="showHoldValues">
                    {{ s.hold_value }}
                  </span>  
                </td>
                <td style="text-align: right">
                  <span v-if="highlighted_samples[s.id] != null">
                    <span
                      v-if="highlighted_samples[s.id].hasOwnProperty('value')"
                    >
                      <span
                        v-if="!isNaN(highlighted_samples[s.id].value)"
                      >
                        {{ highlighted_samples[s.id].value.toFixed(s.decimals) }}
                      </span>
                    </span>
                    <span v-else>
                      <span
                        v-if="!isNaN(highlighted_samples[s.id].minVal)"
                      >
                        {{ highlighted_samples[s.id].minVal.toFixed(s.decimals) }}
                        <span
                          v-if="
                            highlighted_samples[s.id].minVal.toFixed(
                              s.decimals
                            ) !=
                              highlighted_samples[s.id].maxVal.toFixed(s.decimals)
                          "
                        >
                          -
                          {{
                            highlighted_samples[s.id].maxVal.toFixed(s.decimals)
                          }}
                        </span>
                      </span>
                    </span>
                    {{ s.unit }}
                  </span>
                </td>
              </tr>
            </div>
            <div v-if="!showHoldValues">
              <tr v-for="s in channel_series" :key="s.id">
                <td style="width: 180px">
                  <input
                    class="wm_checkbox"
                    type="checkbox"
                    v-on:input="toggle_hidden(s)"
                    :checked="!is_hidden(s)"
                    :title="$txt.hint_show_channel_in_graph"
                  />

<!--                   <IconBtn
                    size="15"
                    :icon="$icon.graph_settings"
                    :tooltip="$txt.open_settings_for_serie"
                    @click="show_channel_serie_options(s)"
                  /> -->
                  <a
                    class="wm_icon_btn"
                    :title="$txt.open_settings_for_serie"
                    @click="show_channel_serie_options(s)"
                    :style="{color:'gray'}"
                  >
                    <svg-icon size="16" viewbox="0 0 18 18" :path="settingsIcon" />
                  </a> 
                  <span 
                    class="wm_table_colored_box"
                    draggable="true"
                    @dragstart="startDrag($event, s)"
                    :style="{
                    backgroundColor: is_hidden(s) ? 'gray' : s.color,cursor: 'grab'
                    }"
                    :width="150"
                    :title="$txt.hint_channel_name"
                    @click="toggleSelectYAxisFromChannel(s)"
                  >
                    {{s.name}}
                  </span>
                </td>
                <td style="width: 120px; text-align: right">
                  <span v-if="highlighted_samples[s.id] != null">
                    <span
                      v-if="highlighted_samples[s.id].hasOwnProperty('value')"
                    >
                      <span
                        v-if="!isNaN(highlighted_samples[s.id].value)"
                      >
                        {{ highlighted_samples[s.id].value.toFixed(s.decimals) }}
                      </span>
                    </span>
                    <span v-else>
                      <span
                        v-if="!isNaN(highlighted_samples[s.id].minVal)"
                      >
                        {{ highlighted_samples[s.id].minVal.toFixed(s.decimals) }}
                        <span
                          v-if="
                            highlighted_samples[s.id].minVal.toFixed(
                              s.decimals
                            ) !=
                              highlighted_samples[s.id].maxVal.toFixed(s.decimals)
                          "
                        >
                          -
                          {{ highlighted_samples[s.id].maxVal.toFixed(s.decimals)
                          }}
                        </span>
                      </span>
                    </span>
                    {{ s.unit }}
                  </span>
                </td>
              </tr>
            </div>
          </tbody>
        </table>
      </div>
       <div class="tg_timegraph" 
            @drop="onDrop($event, 1)"
            @dragover.prevent
            @dragenter.prevent
            style="overflow:visible">
        
        <div style="position:relative">
          <div
            class="wm_overlay_icon"
            :style="{
              top: height / 2 - 25 + 'px',
              left: width / 2 - 60 + 'px'
            }"
            v-if="loading_data"
          >
            <SmallIcon
              class="r rotating"
              icon="cached"
              size="30"
              color="rgba(0,0,0,0.3)"
            />
            <p class="no_data_text text_shadow">
              {{ $txt.loading }} {{ loading_progress }}
            </p>
          </div>
          <div
            class="wm_overlay_icon"
            v-if="channel_series.length == 0 && !loading_data"
            :style="{
              top: height / 2 - 25 + 'px',
              left: width / 2 - 60 + 'px'
            }"
          >
            <span class="no_data_text">{{ $txt.no_data }}</span>
          </div>
        </div>

        <svg :id="canvas_id" ref="abc" :height="height" :width="width">
          <defs>
            <clipPath id="clipPath">
              <rect id="tg_clipping_rect" x="15" y="15" width="0" height="0" />
            </clipPath>
          </defs>

          <g class="tg_axis"></g>
          <g class="tg_axis_grid"></g>
          <g class="tg_left_axis"></g>

          <line
            id="tg_vertical_line"
            class="tg_vertical_line tg_only_on_hover"
            style="clip-path: url(#clipPath)"
          >
            Tooltip
          </line>

          <path class="tg_path"></path>

          <line id="tg_zero_line"></line>
          <line id="tg_value_line"></line>
          <line id="tg_alarm_low_line" class="tg_alarm_lines"></line>
          <line id="tg_alarm_high_line" class="tg_alarm_lines"></line>
          <g
            class="tg_focus_circles tg_only_on_hover"
            style="clip-path: url(#clipPath)"
          ></g>
          <rect
            id="tg_tooltip"
            class="tg_tooltip tg_only_on_hover"
            width="88"
            height="20"
            style="display: none;"
          ></rect>
          <text
            y="20"
            x="50"
            class="tg_only_on_hover"
            id="tg_tooltip_text"
            fill="black"
            style="display: none;"
          >
            -
          </text>
          <text
            y="20"
            x="50"
            class="tg_only_on_hover"
            id="tg_date_display"
            fill="black"
          ></text>
          <rect id="tg_magnify_rect"></rect>
          <g class="tg_series_container" style="clip-path: url(#clipPath)"></g>
          <rect
            class="tg_overlay"
            fill="rgba(0,0,0,0)"
            @mousemove="mousedrag"
            @mousedown="mousedown"
          ></rect>
        </svg>

        <div
          class="tg_actions_bar"
          :style="{ 'padding-left': this.paddingXLeft + 'px' }"
        >
          <IconBtn
            :icon="$icon.graph_settings"
            :tooltip="$txt.open_settings_for_graph"
            @clicked="settings_modal = true"
          />
          <IconBtn
            :icon="$icon.download_graph_img"
            :tooltip="$txt.download_as_image"
            @clicked="download_img()"
          />
          <IconBtn
            v-show="!magnify_mode && !disable_zoom"
            :icon="$icon.zoom_area"
            :tooltip="$txt.zoom_to_area"
            @clicked="magnify_mode = true"
          />
          <IconBtn
            v-show="interval_scaling_factor != 1 || offset_ms != 0"
            :icon="$icon.clear_zoom_and_offset"
            :tooltip="$txt.reset_zoom_and_movement"
            @clicked="
              reset_zoom_and_movement();
              on_displayed_area_changed(true);
              magnify_mode = false;
              resize();
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { render_graph, init_graph, clearXAxisSelectionColor, number_of_stacked_graphs } from "@/recordings/graphrender";
const Enum = require("@/utils/enums").default;
const settingsIcon="M15.95 10.78c.03-.25.05-.51.05-.78s-.02-.53-.06-.78l1.69-1.32c.15-.12.19-.34.1-.51l-1.6-2.77c-.1-.18-.31-.24-.49-.18l-1.99.8c-.42-.32-.86-.58-1.35-.78L12 2.34a.4.4 0 00-.4-.34H8.4c-.2 0-.36.14-.39.34l-.3 2.12c-.49.2-.94.47-1.35.78l-1.99-.8c-.18-.07-.39 0-.49.18l-1.6 2.77c-.1.18-.06.39.1.51l1.69 1.32c-.04.25-.07.52-.07.78s.02.53.06.78L2.37 12.1c-.15.12-.19.34-.1.51l1.6 2.77c.1.18.31.24.49.18l1.99-.8c.42.32.86.58 1.35.78l.3 2.12c.04.2.2.34.4.34h3.2c.2 0 .37-.14.39-.34l.3-2.12c.49-.2.94-.47 1.35-.78l1.99.8c.18.07.39 0 .49-.18l1.6-2.77c.1-.18.06-.39-.1-.51l-1.67-1.32zM10 13c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3z";

export default {
    setup() {
      return { 
        settingsIcon
      }
    }, 
    name: "TimeGraph",
    data() {
        return {
            resetDiagramSettingsBool: false,
            hasDoneInitialLoad: false,
            settings_loaded_from_report: false,
            diagramSettingsStored: this.storeDiagramSettings(),
            width: 1,
            height: 1,
            settings_modal: false,
            use_custom_size: false,
            download_format: "png",
            custom_width: 1000,
            custom_height: 800,
            wait_before_load: 100,
            offset_ms: 0,
            offset_ms_before_pan: 0,
            interval_scaling_factor: 1,
            pan_start_x_pos: 0,
            magnify_start_position: null,
            magnify_mode: false,
            display_area_change_tstamp: null,
            displayedDates: null,
            highlighted_samples: {},
            last_length: 0,
            tick: null,
            raf_id: null,
            pan_start_y_pos: 0,
            offset_y_before_pan: 0,
            axesData: {
                selectedId: "",
                selectedIndex: -1,
                selectedX: false,
                transpose:null,
                array: null
            },
            channel_series: this.series,
            channel_serie: null,
            channel_serie_options: {
                title: "",
                yLabel: "",
                override_y_axis_scale: false,
                override_y_axis_scale_min: 0,
                override_y_axis_scale_max: 0,
                grid: false,
                zero_line: false,
                draw_line_at: false,
                draw_line_at_value : 100,
                graph_type: this.$enum.graph_type.graph_type_line
            },
            channel_serie_options_modal: false,
            axis_options: {
                title: "",
                unitLabel: "",
                yLabel: "",
                override_y_axis_scale: false,
                override_y_axis_scale_min: 0,
                override_y_axis_scale_max: 200,
                grid: false,
                zero_line: false,
                draw_line_at: false,
                draw_line_at_value : 100
            },
            axis_options_modal: false,
            new_graph_description: "",
            graph_description: "Test",
            graph_description_modal: false,
            selected_graph_setting: null,
            show_legend: false,
            show_channel_control_panel: true,
            save_graph_settings_as_modal: false,
            selected_save_graph_settings_as:null,
            save_graph_settings_as_name:"",
            localStorageId:"",
            dragChannel:false,
            showHoldValues: false
        };
    },
    mounted() {
        this.$refs.my_timegraph.addEventListener("wheel", this.handle_scroll, {
          capture: false,
          passive: false
        });
        window.addEventListener("resize", this.resize);
        this.show_channel_control_panel = this.show_channel_controls;
        this.graph_description = this.default_graph_description;
        this.localStorageId=this.local_storage_id; 
        let selectedGraphSettingName = localStorage.getItem(this.localStorageId);
        console.log("Local storage:"+selectedGraphSettingName);
        if (selectedGraphSettingName != null) {
            this.selected_graph_setting = this.settingIdOf(selectedGraphSettingName);
        }
        this.tick = window.setInterval(this.on_graph_update_tick, 200, this);
        init_graph(this.canvas_id, this.height, this.width);
        this.resize();
        this.load_graph_settings_from_saved_report_diagram();
        setTimeout(() => {
          this.$emit("reload-graph");
        }, 250);
    },
    unmounted() {
        window.clearInterval(this.tick);
        window.removeEventListener("resize", this.resize);
        window.removeEventListener("wheel", this.handle_scroll);
    },
    methods: {
      storeDiagramSettings() {
        try {
          if (this.diagram.diagramSettings) {
            return this.storeDiagramSettings = JSON.parse(JSON.stringify(this.diagram.diagramSettings));
          }
          else {
            return "";
          }
        }
        catch {

        }
      },
      emitDiagramSettings() {
          // Collect diagram settings data
          let valueJsonStr = this.settings_string();
          const diagramSettings = {
            settings: valueJsonStr,
            localSettings: "",
            xAxisZoomFactor: "", //implement later to save zoom
            yAxisZoomFactor: "", //implement later to save zoom
            xOffset: "", //implement later to save offset
            yOffset: "", //implement later to save offset
          };
          this.$emit('diagram-settings-updated', diagramSettings);
      },
      show_hold_values_in_graph() {
         let channelColumn = document.getElementById("my_channel_column");
         channelColumn.style.width = this.showHoldValues ? "400px":"550px";
      },
        save_hold_values_to_localStorage() {
          for (let node of this.$app.sltd.project.all_nodes) {
            localStorage["C3_Hold_"+node.cross_prj_identifier] = JSON.stringify(
              node.app_prop.held_vals
            );
          }
        },
        hold_values() {
            console.log("TimeGraph.hold_values hold");
            for (let node of this.nodes) {
              node.hold_values();
            }
        },
        fill_channels() {
          console.log("TimeGraph.fill_channels");
          this.$emit("fill_channels");
        },
        clear_channels() {
          console.log("TimeGraph.clear_channels_channels");
          this.$emit("clear_channels");
        },
        select_nodes() {
          console.log("TimeGraph.select_nodes");
          this.$emit("select_nodes");
        },
        done_select_nodes() {
          console.log("TimeGraph.done_select_nodes");
          this.$emit("done_select_nodes");
        },
        on_graph_update_tick() {
          
          if (this.autoshift) {
            if (this.channel_series.length != this.last_length) {
              this.last_length = this.channel_series.length;
              this.reset_graph_settings(true, false);
            } else {
              this.resize();
            }
          }
        },
        deselectYAxis() {
            this.axesData.selectedId = "";
            this.axesData.selectedIndex = -1;
            console.log("Deselect y-axis");
        },
        selectThisYAxis(index, select) {
            this.dragChannel = false;
            if (select) {
                this.axesData.selectedId = "tg_axis_"+index;
                this.axesData.selectedIndex = index;
                clearXAxisSelectionColor(this.canvas_id);
            }
            else {
                this.axesData.selectedId = "";
                this.axesData.selectedIndex = -1;
            }
            console.log("Select this y-axis:" + select);
        },
        toggleSelectYAxisFromChannel(serie) {
          console.log(serie);
          console.log(this.axesData);
          this.dragChannel = false;

          let axesCount = 0;
          let axisIndex = 0;
          let axisFound = false;

          if (this.axesData.array != null) {
              // Find the number of axes
              axesCount = this.axesData.array.length;
          }

          for (axisIndex = 0; (axisIndex < axesCount) && !axisFound; axisIndex++) {
              let seriesOnAxis = this.axesData.array[axisIndex].series.includes(serie);
              if (seriesOnAxis) {
                  // Found the axis for this series
                  axisFound = true;

                  // Check if the axis is already selected
                  if (axisIndex == this.axesData.selectedIndex) {
                      // Axis was selected, now check if the same channel is clicked again
                      if (this.axesData.selectedChannelId === serie.id) {
                          // Deselect the axis if the same channel is clicked again
                          this.axesData.selectedChannelId = null;
                          this.selectThisYAxis(axisIndex, false); // Deselect axis
                      } else {
                          // A different channel on the same axis was clicked; just update the selected channel
                          this.axesData.selectedChannelId = serie.id;
                          this.selectThisYAxis(axisIndex, true); // Keep axis selected
                      }
                  } else {
                      // Axis was not selected, select it
                      this.axesData.selectedIndex = axisIndex;
                      this.axesData.selectedChannelId = serie.id; // Update the selected channel
                      this.selectThisYAxis(axisIndex, true); // Select axis
                  }

                  // Resize to update visuals
                  this.resize();
              }
          }

          console.log("Toggle select Y-axis from channel");
      },
        zoomYAxis(deltaY) {
            if ((this.axesData.selectedId == "") || (this.axesData.selectedIndex < 0))
                return false;
            else {
                let ZOOM_SPEED_FACTOR = 0.002;
                let ZOOM_MIN = 1;
                let ZOOM_MAX = 10;
                this.axesData.transpose[this.axesData.selectedIndex].zoomFactor += ZOOM_SPEED_FACTOR * deltaY;
                if (this.axesData.transpose[this.axesData.selectedIndex].zoomFactor < ZOOM_MIN) {
                    this.axesData.transpose[this.axesData.selectedIndex].zoomFactor = ZOOM_MIN;
                }
                if (this.axesData.transpose[this.axesData.selectedIndex].zoomFactor > ZOOM_MAX) {
                    this.axesData.transpose[this.axesData.selectedIndex].zoomFactor = ZOOM_MAX;
                }
                this.axesData.transpose[this.axesData.selectedIndex].unit = this.axesData.array[this.axesData.selectedIndex].unit;
                this.axesData.transpose[this.axesData.selectedIndex].gi = this.axesData.array[this.axesData.selectedIndex].gi;
                return true;
            }
        },
        select_deselect_all() {
          if (this.graph_buffer.hidden_series.length === 0) {
          // Emit an event to hide all series
          this.$emit('select-deselect-all-series', false);
          } else {
          // Emit an event to show all series
          this.$emit('select-deselect-all-series', true);
          }
          this.resize();
        },
        resize() {
            if (this.fixed_width == null) {
                let f = this.$refs[this.unique_identifier];
                this.width = f.clientWidth;
            }
            else {
                this.width = this.fixed_width;
            }
            if (this.fixed_height == null) {
                this.height = 400;
            }
            else {
                this.height = this.fixed_height;
            }
            if (this.use_custom_size)
            {
              this.width = this.custom_width;
              this.height = this.custom_height;
            }

            let graph_interval_begin = this.start_date;
            let graph_interval_end = this.end_date;
            if (this.autoshift) {
                let now = Date.now();
                let original_duration = this.end_date - this.start_date;
                graph_interval_begin = now - original_duration;
                graph_interval_end = now;
            }
            this.update_graph(graph_interval_begin, graph_interval_end);
        },
        mousedown(event) {
            this.dragChannel = false;
            if (this.axesData.selectedIndex < 0) {
                // drag X-axis
                if (!this.magnify_mode && this.magnify_start_position == null) {
                    this.on_pan_start(event.clientX);
                }
                //this.resize();  
            }
            else {
                // drag selected Y-axis
                this.on_pan_y_start(event.clientY);
            }
            this.resize();
        },
        mousedrag(event) {
           // this.dragChannel = false;
            if (this.dragChannel)
              return;
            if (this.axesData.selectedIndex < 0) {
                // drag X-axis
                if (this.$ui.mousedown && !this.magnify_mode) {
                    this.on_pan(event.clientX);
                }
            }
            else {
                if (this.$ui.mousedown)
                    this.on_pan_y(event.clientY);
            }
        },
        on_pan_start(mouse_x_position) {
            this.offset_ms_before_pan = this.offset_ms;
            this.pan_start_x_pos = mouse_x_position;
            console.log("on_pan_start");
        },
        on_pan_y_start(mouse_y_position) {
            this.offset_y_before_pan = this.axesData.transpose[this.axesData.selectedIndex].offset;
            this.pan_start_y_pos = mouse_y_position;
            console.log("on_pan_y_start");
        },
        on_pan_y(mouse_y_position) {
            let y_per_pixel = (this.axesData.array[this.axesData.selectedIndex].maxY - this.axesData.array[this.axesData.selectedIndex].minY) /
                this.height;
            //      let delta_y = (mouse_y_position - this.pan_start_y_pos) * y_per_pixel;
            let delta_y = (mouse_y_position - this.pan_start_y_pos) * 1;
            this.axesData.transpose[this.axesData.selectedIndex].offset = this.offset_y_before_pan + delta_y;
            this.axesData.transpose[this.axesData.selectedIndex].unit = this.axesData.array[this.axesData.selectedIndex].unit;
            this.axesData.transpose[this.axesData.selectedIndex].gi = this.axesData.array[this.axesData.selectedIndex].gi;

            this.on_displayed_area_changed(true);
            console.log("on_pan_y");
            this.resize();
        },
        on_pan(mouse_x_position) {
            if (this.displayedDates != null) {
              let ms_per_pixel = (this.displayedDates.maxDate - this.displayedDates.minDate) /
                this.width;
              let delta_ms = (this.pan_start_x_pos - mouse_x_position) * ms_per_pixel;
              this.offset_ms = this.offset_ms_before_pan + delta_ms;
              console.log("on_pan");
              this.on_displayed_area_changed(true);
              this.resize();
            }
        },
        on_click_graph(timestamp) {
            if (this.magnify_mode) {
                if (this.magnify_start_position == null) {
                    this.start_magnifying(timestamp);
                }
                else {
                    this.finish_magnifying(timestamp);
                }
            }
        },
        on_click_axis_label(axisIndex, unitLabel,
                override_y_axis_scale, 
                override_y_axis_scale_min,
                override_y_axis_scale_max, 
                grid, zero_line, 
                draw_line_at, draw_line_at_value, label) {
            this.axis_options.title = this.$txt.axis_settings + ":" + unitLabel;
            this.axis_options.unitLabel = unitLabel;
            this.axis_options.override_y_axis_scale = override_y_axis_scale; 
            this.axis_options.override_y_axis_scale_min = override_y_axis_scale_min;
            this.axis_options.override_y_axis_scale_max = override_y_axis_scale_max; 
            this.axis_options.grid = grid;
            this.axis_options.zero_line = zero_line;
            this.axis_options.draw_line_at = draw_line_at;
            this.axis_options.draw_line_at_value = draw_line_at_value;
            this.axis_options.yLabel = label;
            this.axis_options_modal = true;
            this.new_graph_description = this.graph_description;
        },
        on_click_graph_description() {
            this.new_graph_description = this.graph_description;
            this.graph_description_modal = true;
        },
        on_click_y_axis() {
            this.dragChannel = false;
            console.log("Y-axis clicked!");
        },
        start_magnifying(timestamp) {
            this.magnify_start_position = timestamp;
            this.resize();
        },
        finish_magnifying(timestamp) {
            this.magnify_mode = false;
            this.set_displayed_area_to_dates(this.magnify_start_position, timestamp);
            this.magnify_start_position = null;
            this.on_displayed_area_changed(true);
            this.resize();
        },
        set_displayed_area_to_dates(date1, date2) {
            this.reset_zoom_and_movement();
            let from = date1;
            let to = date2;
            if (date1 > date2) {
                to = date1;
                from = date2;
            }
            let area_duration = to - from;
            let default_duration = this.end_date - this.start_date;
            this.interval_scaling_factor = area_duration / default_duration;
            let avg_time = from + area_duration / 2;
            let default_avg_time = this.start_date + default_duration / 2;
            this.offset_ms = avg_time - default_avg_time;
        },
        handle_scroll(event) {
            var scrollOnChart = true;
            if (this.dragChannel)
              scrollOnChart = false;
            if (!scrollOnChart) {
                return;
            }
            if (this.zoomYAxis(event.deltaY)) {
                // prevent scroll on forms scroll-bar
                event.preventDefault();
                this.on_displayed_area_changed(true);
                this.resize();
            }
            else {
                let ZOOM_SPEED_FACTOR = 0.0002;
                this.interval_scaling_factor +=
                    ZOOM_SPEED_FACTOR * event.deltaY * this.interval_scaling_factor;
                event.preventDefault();
                let MAX_SCALE = 500;
                let MIN_SCALE = 0;
                if (this.interval_scaling_factor > MAX_SCALE)
                    this.interval_scaling_factor = MAX_SCALE;
                if (this.interval_scaling_factor < MIN_SCALE)
                    this.interval_scaling_factor = MIN_SCALE;
                this.on_displayed_area_changed(true);
                this.resize();
            }
        },
        on_displayed_area_changed(cache_this_display_request = false) {
            this.display_area_change_tstamp = Date.now();
            if (this.displayedDates != null) {
              window.setTimeout(() => {
                this.$emit("displayed_area_changed", {
                    minDate: this.displayedDates.minDate,
                    maxDate: this.displayedDates.maxDate
                }, 0, this);
              });
            }
            window.setTimeout(this.reload_data, this.wait_before_load, this, cache_this_display_request);
        },
          reload_data(that, cache_this_display_request) {
              let deltaTime = Date.now() - that.display_area_change_tstamp;
              if (deltaTime >= this.wait_before_load) {
                  that.display_area_change_tstamp = Date.now();
                  let minDate, maxDate;
                  if (that.displayedDates != null) {
                  let overshoot = that.area_overshoot *
                      (that.displayedDates.maxDate - that.displayedDates.minDate);
                  minDate = that.displayedDates.minDate - overshoot;
                  maxDate = that.displayedDates.maxDate + overshoot;
                  } else {
                  // Use initial start_date and end_date when displayedDates is null
                  minDate = that.start_date;
                  maxDate = that.end_date;
                  }
                  that.$emit("reload_data", {
                      minDate: minDate,
                      maxDate: maxDate,
                      cache: cache_this_display_request
                  });
              }
          },

      //   reload_data(that, cache_this_display_request) {
      //       let deltaTime = Date.now() - that.display_area_change_tstamp;
      //       if (deltaTime >= this.wait_before_load) {
      //           that.display_area_change_tstamp = Date.now();
      //           let overshoot = that.area_overshoot *
      //               (that.displayedDates.maxDate - that.displayedDates.minDate);
      //           that.$emit("reload_data", {
      //               minDate: that.displayedDates.minDate - overshoot,
      //               maxDate: that.displayedDates.maxDate + overshoot,
      //               cache: cache_this_display_request
      //           });
      //       }
      //   },
        toggle_hidden(s) {
          if (this.is_hidden(s)) {
          // Emit an event to remove the series from hidden_series
          this.$emit('toggle-hidden-series', s.id, false);
          } else {
          // Emit an event to add the series to hidden_series
          this.$emit('toggle-hidden-series', s.id, true);
          }
          this.emitDiagramSettings();
          this.resize();
        },
        is_hidden(s) {
            return this.graph_buffer.hidden_series.includes(s.id);
        },
        download_img() {
            console.log("download_img");
            if (this.download_format == "svg") {
                this.$util.svg_element_download_as_file(document.getElementById(this.canvas_id), "test");
            }
            else {
                this.$util.svg_element_to_img_download(document.getElementById(this.canvas_id), "test");
            }
        },
        reset_zoom_and_movement() {
            this.interval_scaling_factor = 1;
            this.offset_ms = 0;
            this.offset_ms_before_pan = 0;
        },
        show_channel_serie_options(s) {
            this.channel_serie = s;
            this.channel_serie_options.title = this.$txt.channel_serie_settings + ":" + s.name;
            if (this.$app.settings.display_internal_id) {
              let internal_id_string = " (" + this.$txt.internal_id + ":" + s.id + ")";
              this.channel_serie_options.title += internal_id_string;
             }
            this.channel_serie_options_modal = true;
            this.channel_serie_options.override_y_axis_scale = s.override_y_axis_scale;
            this.channel_serie_options.override_y_axis_scale_min = s.override_y_axis_scale_min;
            this.channel_serie_options.override_y_axis_scale_max = s.override_y_axis_scale_max;
            this.channel_serie_options.grid = s.grid;
            this.channel_serie_options.zero_line = s.zero_line;
            this.channel_serie_options.draw_line_at = s.draw_line_at;
            this.channel_serie_options.draw_line_at_value = s.draw_line_at_value;
            this.channel_serie_options.yLabel = s.yLabel;
            this.channel_serie_options.yPos = s.yPos;
            this.channel_serie_options.graph_type = s.graph_type;
        },
        submit_channel_serie_options() {
            this.channel_serie.override_y_axis_scale = this.channel_serie_options.override_y_axis_scale;
            this.channel_serie.override_y_axis_scale_min = this.channel_serie_options.override_y_axis_scale_min;
            this.channel_serie.override_y_axis_scale_max = this.channel_serie_options.override_y_axis_scale_max;

            this.channel_serie.grid = this.channel_serie_options.grid;
            this.channel_serie.zero_line = this.channel_serie_options.zero_line;
            this.channel_serie.draw_line_at = this.channel_serie_options.draw_line_at;
            this.channel_serie.draw_line_at_value = this.channel_serie_options.draw_line_at_value;
            this.channel_serie.yLabel = this.channel_serie_options.yLabel;

            this.channel_serie.yPos = this.channel_serie_options.yPos;
            this.channel_serie.graph_type = this.channel_serie_options.graph_type;

            let thisUnit = this.channel_serie.unit;
            let thisYPos = this.channel_serie.yPos;

            let i = 0;
            for (i = 0; i < this.channel_series.length; i++) {
                if ((this.channel_series[i].unit == thisUnit) && (this.channel_series[i].yPos == thisYPos)) {
                    this.channel_series[i].override_y_axis_scale = this.channel_serie_options.override_y_axis_scale;
                    this.channel_series[i].override_y_axis_scale_min = this.channel_serie_options.override_y_axis_scale_min;
                    this.channel_series[i].override_y_axis_scale_max = this.channel_serie_options.override_y_axis_scale_max;
                    this.channel_series[i].grid = this.channel_serie_options.grid;
                    this.channel_series[i].zero_line = this.channel_serie_options.zero_line;
                    this.channel_series[i].draw_line_at = this.channel_serie_options.draw_line_at;
                    this.channel_series[i].draw_line_at_value = this.channel_serie_options.draw_line_at_value;
                    this.channel_series[i].yLabel = this.channel_serie_options.yLabel;
                }
            }
            this.emitDiagramSettings();
        },
        submit_axis_options() {
            //      console.log("submit_axis_options");
            this.graph_description = this.new_graph_description;
            let i = 0;
            for (i = 0; i < this.channel_series.length; i++) {
//                console.log(this.series[i].unit + "!=" + this.axis_options.unitLabel);
                if (this.channel_series[i].unit == this.axis_options.unitLabel) {
                    //          console.log(this.series[i].name + ".grid="+ this.axis_options.grid);
                    this.channel_series[i].override_y_axis_scale = this.axis_options.override_y_axis_scale;
                    this.channel_series[i].override_y_axis_scale_min = this.axis_options.override_y_axis_scale_min;
                    this.channel_series[i].override_y_axis_scale_max = this.axis_options.override_y_axis_scale_max;
                    this.channel_series[i].grid = this.axis_options.grid;
                    this.channel_series[i].zero_line = this.axis_options.zero_line;
                    this.channel_series[i].draw_line_at = this.axis_options.draw_line_at;
                    this.channel_series[i].draw_line_at_value = this.axis_options.draw_line_at_value;
                    this.channel_series[i].yLabel = this.axis_options.yLabel;
                }
            }
            this.emitDiagramSettings();
        },
        submit_graph_description() {
            this.graph_description = this.new_graph_description;
            this.graph_description_modal = false;
        },
        update_graph(graph_interval_begin, graph_interval_end) {
            if (this.channel_series.length != this.series.length)
              this.channel_series = this.series;
            if (this.raf_id == null) {
                this.raf_id = requestAnimationFrame(() => this.render_internal(graph_interval_begin, graph_interval_end));
            }
        },
        settingIdOf(name) {
          let existingGraphProps =
                this.available_graph_settings.filter(s => (s.name == name));
          if (existingGraphProps.length < 1)        
            return 0;
          else
            return existingGraphProps[0].id;
        },
        settingOf(name) {
          let existingGraphProps =
                this.available_graph_settings.filter(s => (s.name == name));
          if (existingGraphProps.length < 1)        
            return 0;
          else
            return existingGraphProps[0];
        },
        getNameOfGraphProps(id) {
            if (this.$app.graph_props == null)          
              return "";
            let selectedGraphProps = this.$app.graph_props.filter(s => (s.id == id));
            if (selectedGraphProps.length < 1)
                return "";
            else
                return selectedGraphProps[0].name;
        },
        getValueOfGraphProps(id) {
            let selectedGraphProps = this.$app.graph_props.filter(s => (s.id == id));
            if (selectedGraphProps.length < 1)
                return "";
            else
                return selectedGraphProps[0].value;
        },
        prepare_to_save_graph_settings_as() {
            this.save_graph_settings_as_name = "";
            this.save_graph_settings_as_modal = true;
        },
        settings_string() {
            let settings = {};
            settings.graph_description = this.graph_description;
            settings.show_legend = this.show_legend;
            settings.show_channel_control_panel = this.show_channel_control_panel;

            settings.use_custom_size = this.use_custom_size;
            settings.download_format = this.download_format;
            settings.custom_width=this.custom_width;
            settings.custom_height=this.custom_height;

            let series = [];
            for (let i = 0; i < this.channel_series.length; i++) {
                let serie = {};
                // Save only the attributes needed
                serie.id = this.channel_series[i].id;

                if (this.channel_series[i].override_y_axis_scale == null)
                  serie.override_y_axis_scale = false;
                else
                  serie.override_y_axis_scale = this.channel_series[i].override_y_axis_scale;

                if (this.channel_series[i].override_y_axis_scale_min == null)
                  serie.override_y_axis_scale_min = 0;
                else
                  serie.override_y_axis_scale_min = this.channel_series[i].override_y_axis_scale_min;

                if (this.channel_series[i].override_y_axis_scale_max == null)
                  serie.override_y_axis_scale_max = 200;
                else
                  serie.override_y_axis_scale_max = this.channel_series[i].override_y_axis_scale_max;

                if (this.channel_series[i].grid == null)
                  serie.grid = false;
                else
                  serie.grid = this.channel_series[i].grid;

                if (this.channel_series[i].zero_line == null)
                  serie.zero_line = false;
                else
                  serie.zero_line = this.channel_series[i].zero_line;

                if (this.channel_series[i].draw_line_at == null)
                  serie.draw_line_at = false;
                else
                  serie.draw_line_at = this.channel_series[i].draw_line_at;

                if (this.channel_series[i].draw_line_at_value == null)
                  serie.draw_line_at_value = 100;
                else
                  serie.draw_line_at_value = this.channel_series[i].draw_line_at_value;

                if (this.channel_series[i].yLabel == null)
                  serie.yLabel = "";
                else
                  serie.yLabel = this.channel_series[i].yLabel;

                if (this.channel_series[i].yPos == null)
                  serie.yPos = this.$enum.graph_pos.graph_pos_bottom;
                else
                  serie.yPos = this.channel_series[i].yPos;

                if (this.channel_series[i].graph_type == null)
                  serie.graph_type = this.$enum.graph_type.graph_type_line;
                else
                  serie.graph_type = this.channel_series[i].graph_type;

                series.push(serie);
            }
            settings.series = series;
            settings.hidden_series = this.graph_buffer.hidden_series;
            let valueJsonStr = JSON.stringify(settings);
            return valueJsonStr;
        },
        reset_graph_settings(refresh, resetDiagramSettings) {
          console.log("refresh: ", refresh, "reset: ", resetDiagramSettings)
          console.log("stored settings:", this.diagramSettingsStored)
          if (this.diagram && this.diagram.diagramSettings && resetDiagramSettings == false) {
            this.load_graph_settings_from_saved_report_diagram()
          } else {
            this.resetDiagramSettingsBool = true;
            this.selected_graph_setting = null;
            this.graph_description = this.default_graph_description;
            this.show_legend = false;
            this.show_channel_control_panel = true;
  
            this.use_custom_size=false;
            this.custom_width=1000;
            this.custom_height=800;
  
            this.download_format="png";
  
            for (let i = 0; i < this.channel_series.length; i++) {
              this.set_default_serie_setting(i);
              this.channel_series[i].settings_updated = true;
            }
  
            if (refresh) {
              this.graph_buffer.reset();
              this.resize();
              this.on_selection_changed();
            }
          }
          this.emitDiagramSettings();
        },
        save_graph_settings() {
            let valueJsonStr = this.settings_string();
            console.log("graph settings?: ", valueJsonStr);
            let zoneId = 0;
            if (this.zone != null) 
              zoneId = this.zone.id;

            let controllerId = 0;
            if (this.controller != null) 
              controllerId = this.controller.id;

            //console.log(valueJsonStr);
            let name = this.getNameOfGraphProps(this.selected_graph_setting);
            if (valueJsonStr != this.getValueOfGraphProps(this.selected_graph_setting)) {
                this.$net.graph_props.update(this.$app.sltd.project.id, this.selected_graph_setting, {
                    controllerId : controllerId,
                    zoneId: zoneId,
                    name: name,
                    value: valueJsonStr
                });
            }
        },
        save_graph_settings_as() {
            let valueJsonStr = this.settings_string();

            let zoneId = 0;
            if (this.zone != null) 
              zoneId = this.zone.id;

            let controllerId = 0;
            if (this.controller != null) 
              controllerId = this.controller.id;

            //console.log(valueJsonStr);
              // setting already exists?
            let id = this.settingIdOf(this.save_graph_settings_as_name);
            if (id > 0) {
              if (valueJsonStr != this.getValueOfGraphProps(id)) {
                this.$net.graph_props.update(this.$app.sltd.project.id, id, {
                    controllerId : controllerId,
                    zoneId: zoneId,
                    name: this.save_graph_settings_as_name,
                    value: valueJsonStr
                });
              }
            }
            else {
              this.$net.graph_props.create(this.$app.sltd.project.id, {
                    controllerId : controllerId,
                    zoneId: zoneId,
                    name: this.save_graph_settings_as_name,
                    value: valueJsonStr
                });
            }
        },

        delete_graph_setting(id) {
          this.$net.graph_props.delete(this.$app.sltd.project.id, id);
        },

        set_default_serie_setting(i) {
          this.channel_series[i].override_y_axis_scale = false;
          this.channel_series[i].override_y_axis_scale_min = 0;
          this.channel_series[i].override_y_axis_scale_max = 200;
          this.channel_series[i].grid = false; 
          this.channel_series[i].zero_line = false;
          this.channel_series[i].draw_line_at = false;
          this.channel_series[i].draw_line_at_value = 100;
          this.channel_series[i].yLabel = "";
          this.channel_series[i].yPos = this.$enum.graph_pos.graph_pos_bottom;        
          this.channel_series[i].graph_type = this.$enum.graph_type.graph_type_line;   
          // Other defaults for Decay result
          if (this.report_type == this.$camur.report_type.reptype_decay_results) {
            this.channel_series[i].override_y_axis_scale = true;
            this.channel_series[i].grid = true;
            this.channel_series[i].draw_line_at = true;
            this.channel_series[i].yLabel = "Depolarization";
          }
        },

        disable_settings_loaded_from_report() {
          this.settings_loaded_from_report = false;
          this.emitDiagramSettings();
        },

        reload_stored_settings() {
          if (this.diagramSettingsStored) {
            //Emit stored settings since chaning props are forbidden
            let valueJsonStr = JSON.stringify(this.diagramSettingsStored);
            const diagramSettings = {
              settings: valueJsonStr,
              xAxisZoomFactor: "",
              yAxisZoomFactor: "",
              xOffset: "",
              yOffset: "",
            };
            // Emit the stored data to the parent
            
            this.$emit('diagram-settings-updated', diagramSettings);
            this.load_graph_settings_from_saved_report_diagram();
            this.$emit("reload-localSettings")
            this.$emit("reload-graph");
          }
        },

        load_graph_settings_from_saved_report_diagram() {
          if (this.diagram && this.diagram.diagramSettings) {
            this.resetDiagramSettingsBool = false;
            this.selected_graph_setting = null;

            const settings = this.diagram.diagramSettings;

            // Apply main settings
            this.graph_description = settings.graph_description;
            this.show_legend = settings.show_legend;
            this.show_channel_control_panel = settings.show_channel_control_panel;
            this.use_custom_size = settings.use_custom_size;
            this.custom_width = settings.custom_width;
            this.custom_height = settings.custom_height;
            this.download_format = settings.download_format;

            // Handle series and hidden_series
            for (let serie of settings.series) {
              // find the matching channel in channel_series
              const existing = this.channel_series.find(cs => cs.id === serie.id);
              if (!existing) {
                // channel doesn't exist, skip or create a new one
                continue;
              }
              
              // Set channel_serie so submit_channel_serie_options() can use it
              this.channel_serie = existing;

              this.channel_serie_options.override_y_axis_scale = serie.override_y_axis_scale;
              this.channel_serie_options.override_y_axis_scale_min = serie.override_y_axis_scale_min;
              this.channel_serie_options.override_y_axis_scale_max = serie.override_y_axis_scale_max;

              this.channel_serie_options.grid = serie.grid;
              this.channel_serie_options.zero_line = serie.zero_line;
              this.channel_serie_options.draw_line_at = serie.draw_line_at;
              this.channel_serie_options.draw_line_at_value = serie.draw_line_at_value;
              this.channel_serie_options.yLabel = serie.yLabel;

              this.channel_serie_options.yPos = serie.yPos;
              this.channel_serie_options.graph_type = serie.graph_type;
              this.channel_serie.settings_updated = true;
              this.submit_channel_serie_options();
            }
            
            this.$emit('set-hidden-series', settings.hidden_series);
            // console.log("channel series loaded in mounted: ", this.channel_series)
            console.log("diagram loaded in mounted: ", this.diagram)
            
            this.settings_loaded_from_report = true;
            // Trigger reactivity
            this.resize(); // Ensure UI reflects updated dimensions
          } else {
            console.error('Diagram settings are missing or invalid. Emitting defaults');
            this.emitDiagramSettings();
          }
        },

        load_graph_settings(id) {
          console.log("load_graph_settings for id:"+ id);
          let selectedGraphProps = this.$app.graph_props.filter(s => (s.id == id));
          // console.log("selectedGraphProps: ", selectedGraphProps)
          // console.log("all graph_props: ", this.$app.graph_props)
          if (selectedGraphProps.length >= 1) {
            let valueString = selectedGraphProps[0].value;
            //console.log("load_graph_settings for id:"+ id + " :"+ valueString);
            let value = JSON.parse(valueString);
            if (value.graph_description == null) {
              this.graph_description = this.default_graph_description;
            }
            else {
              this.graph_description = value.graph_description;
            }
            this.show_legend = value.show_legend;
            this.show_channel_control_panel = value.show_channel_control_panel;

            this.use_custom_size=value.use_custom_size;
            this.download_format=value.download_format;
            this.custom_width=value.custom_width;
            this.custom_height=value.custom_height;

            this.$emit('set-hidden-series', value.hidden_series);
            for (let i = 0; i < this.channel_series.length; i++) {
              let s = this.channel_series[i];
              let setting = value.series.filter(gs => (gs.id == s.id));
              if (setting != null) {
                if (setting.length >= 1) {
                  if (setting[0].override_y_axis_scale == null)
                    this.channel_series[i].override_y_axis_scale = false;
                  else
                    this.channel_series[i].override_y_axis_scale = setting[0].override_y_axis_scale;

                  if (setting[0].override_y_axis_scale_min == null)
                    this.channel_series[i].override_y_axis_scale_min = 0;
                  else
                    this.channel_series[i].override_y_axis_scale_min = setting[0].override_y_axis_scale_min;

                  if (setting[0].override_y_axis_scale_max == null)
                    this.channel_series[i].override_y_axis_scale_max = 200;
                  else
                    this.channel_series[i].override_y_axis_scale_max = setting[0].override_y_axis_scale_max;

                  if (setting[0].grid == null) {  
                    this.channel_series[i].grid = false;                       
                  }
                  else {
                    this.channel_series[i].grid = setting[0].grid;
                  }
                  if (setting[0].zero_line == null) {  
                    this.channel_series[i].zero_line = false;
                  }
                  else {
                    this.channel_series[i].zero_line = setting[0].zero_line;  
                  }
                  if (setting[0].draw_line_at == null) {  
                    this.channel_series[i].draw_line_at = false;
                  }
                  else {
                    this.channel_series[i].draw_line_at = setting[0].draw_line_at;  
                  }
                  if (setting[0].draw_line_at_value == null) {  
                    this.channel_series[i].draw_line_at_value = 100;
                  }
                  else {
                    this.channel_series[i].draw_line_at_value = setting[0].draw_line_at_value;  
                  }
                  if (setting[0].yLabel == null) {
                    this.channel_series[i].yLabel = "";
                  }
                  else {
                    this.channel_series[i].yLabel = setting[0].yLabel;    
                  }    
                  if (setting[0].yPos == null) {
                    this.channel_series[i].yPos = this.$enum.graph_pos.graph_pos_bottom;
                  }
                  else {
                    this.channel_series[i].yPos = setting[0].yPos;
                  }
                  if (setting[0].graph_type == null) {
                    this.channel_series[i].graph_type = this.$enum.graph_type.graph_type_line;
                  }
                  else {
                    this.channel_series[i].graph_type = setting[0].graph_type;
                  }
                  this.channel_series[i].settings_updated = true;
                }
                else {
                  // Set defaults
                  this.set_default_serie_setting(i);
                  this.channel_series[i].settings_updated = true;
                }
              }
              else {
                // Set defaults
                this.set_default_serie_setting(i);
                this.channel_series[i].settings_updated = true;
              }
            }
            console.log("Load " + this.graph_description);
            this.resize();
          }
          else {
            for (let i = 0; i < this.channel_series.length; i++) {
              this.set_default_serie_setting(i);
              this.channel_series[i].settings_updated = true;
            }
          }
        },
        render_internal(graph_interval_begin, graph_interval_end) {
            this.raf_id = null;
            let options = {
                width: this.use_custom_size ? this.custom_width : this.width,
                height: this.use_custom_size ? this.custom_height : this.height,
                click_callback: this.on_click_graph,
                resize_callback: this.resize,
                highlighted_samples_callback: this.set_highlighted_samples,
                startDate: graph_interval_begin,
                endDate: graph_interval_end,
                paddingXLeft: this.paddingXLeft,
                paddingXRight: this.paddingXRight,
                paddingY: this.paddingY,
                canvasId: this.canvas_id,
                area_overshoot: this.area_overshoot,
                maxPointDensity: this.points_density_threshold,
                show_highlights: !this.$ui.mousedown,
                offset_ms: this.offset_ms,
                thick: this.thick,
                interval_scaling_factor: this.interval_scaling_factor,
                position: graph_interval_begin +
                    (graph_interval_end - graph_interval_begin) / 2,
                magnify_start_position: this.magnify_start_position,
                disable_zoom: this.disable_zoom,
                axis_options_callback: this.on_click_axis_label,
                graph_description: this.graph_description,
                graph_description_callback: this.on_click_graph_description,
                show_legend: this.show_legend,
                firstYAxisLeft:this.firstYAxisLeft,
                click_y_axis_callback: this.on_click_y_axis
            };
            //console.log("render_internal, channel_series.length:" + this.channel_series.length)
            if (!this.show_channel_control_panel)
                options.width += 300;
            if (this.show_legend)
                options.paddingXRight += 140;
            this.channel_series.sort((a, b) => (this.sort_string(a) > this.sort_string(b) ? 1 : -1));

            if ((this.channel_series.length > 0) && (!this.channel_series[0].settings_updated)) {
              if (this.selected_graph_setting != null){
                this.load_graph_settings(this.selected_graph_setting);
              }
              else {
                this.reset_graph_settings (false, this.resetDiagramSettingsBool);
              }
            }

            let t_stamp = Date.now();
            try {
              this.displayedDates = render_graph(options, this.axesData, this.channel_series, this.graph_buffer.hidden_series ,this.alarm_level_low, this.alarm_level_high, this.showHoldValues);
            } catch {}
            window.graph_update_time = Date.now() - t_stamp;
            this.updateImageSrc();
        },

        updateImageSrc() {
          const svgElement = document.getElementById(this.canvas_id);

          if (this.download_format === 'svg') {
            // Convert SVG element to data URL
            const serializer = new XMLSerializer();
            const svgData = serializer.serializeToString(svgElement);
            const base64Data = btoa(unescape(encodeURIComponent(svgData)));
            const dataUrl = 'data:image/svg+xml;base64,' + base64Data;

            // Emit the image data to the parent component
            this.$emit('image-updated', dataUrl);
          } else {
            if (svgElement) {
              // Convert SVG to PNG data URL
              const svgData = new XMLSerializer().serializeToString(svgElement);
              const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
              const URL = window.URL || window.webkitURL || window;
              const blobURL = URL.createObjectURL(svgBlob);
  
              const img = new Image();
              img.crossOrigin = 'Anonymous'; // Important for cross-origin images
  
              img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = svgElement.clientWidth;
                canvas.height = svgElement.clientHeight;
                const context = canvas.getContext('2d');
                context.drawImage(img, 0, 0);
  
                // Convert canvas to PNG data URL
                const dataUrl = canvas.toDataURL('image/png');
  
                // Emit the image data to the parent component
                this.$emit('image-updated', dataUrl);
  
                // Clean up
                URL.revokeObjectURL(blobURL);
              };
  
              img.onerror = (error) => {
                console.error('Error converting SVG to PNG:', error);
                // Handle the error as needed
              };
  
              img.src = blobURL;
            }
          }
        },
        sort_string(a) {
          // sortStr will provide a unique string id
          // with a defined sort-order
          let sortStr=a.name + "-" + (10000 + a.id);
          return sortStr;
        },
        set_highlighted_samples(samples) {
            this.highlighted_samples = samples;
        },
        on_selection_changed() {
            this.reset_zoom_and_movement();
            this.resize();
            this.on_displayed_area_changed(true);
        },
        startDrag (evt, item) {
          this.dragChannel = true;
          evt.dataTransfer.dropEffect = 'move'
          evt.dataTransfer.effectAllowed = 'move'
          evt.dataTransfer.setData('itemID', item.id);
          this.deselectYAxis();
        },
        onDrop (evt, list) {
          this.deselectYAxis();
          const itemID = evt.dataTransfer.getData('itemID');
          console.log("Dropped:"+itemID+" at ("+evt.clientX + ", "+evt.clientY+")");	  
          console.log(evt);
          let tg = document.getElementById("my_timegraph");
          let divRect = tg.getBoundingClientRect();

          let visible_series = this.channel_series.filter(s => !this.graph_buffer.hidden_series.includes(s.id));
          let items = this.channel_series.filter(s => (itemID == s.id));
          let item = items[0];
          console.log("item:"+item);

          let graph_N = number_of_stacked_graphs(visible_series);

          // Dropped position in Time-graph
          let yPos = Math.floor(evt.clientY - divRect.y);
          let yGraphHeight = (this.height - 2 * this.paddingY) / graph_N;
          /*console.log("yPos:"+yPos);
          console.log("paddingY:"+this.paddingY);
          console.log("yGraphHeight:"+yGraphHeight);
          console.log("this.height:"+this.height); */

          let droppedYPos = Enum.graph_pos.graph_pos_bottom;
          if (yPos < this.paddingY) {
            // Drop above existing
            if (graph_N <= Enum.graph_pos.graph_pos_top) {
              droppedYPos = graph_N;
              console.log("Dropped YPos:", droppedYPos);
              this.channel_serie = item;
              this.channel_serie_options.override_y_axis_scale = item.override_y_axis_scale;
              this.channel_serie_options.override_y_axis_scale_min = item.override_y_axis_scale_min;
              this.channel_serie_options.override_y_axis_scale_max = item.override_y_axis_scale_max;
              this.channel_serie_options.grid = item.grid;
              this.channel_serie_options.zero_line = item.zero_line;
              this.channel_serie_options.draw_line_at = item.draw_line_at;
              this.channel_serie_options.draw_line_at_value = item.draw_line_at_value;
              this.channel_serie_options.yLabel = item.yLabel;
              this.channel_serie_options.graph_type = item.graph_type;
              this.channel_serie_options.yPos = droppedYPos;
              this.submit_channel_serie_options();
              this.resize();
            }
            // else ignore
          }
          else if (yPos > (this.height - this.paddingY)) {
            // Below graphs, ignore 
          }
          else {
            let yIndex = Math.floor((yPos - this.paddingY) / yGraphHeight);
            droppedYPos = (graph_N-1) - yIndex;
            console.log("Dropped YPos:", droppedYPos);
            this.channel_serie = item;
            this.channel_serie_options.override_y_axis_scale = item.override_y_axis_scale;
            this.channel_serie_options.override_y_axis_scale_min = item.override_y_axis_scale_min;
            this.channel_serie_options.override_y_axis_scale_max = item.override_y_axis_scale_max;
            this.channel_serie_options.grid = item.grid;
            this.channel_serie_options.zero_line = item.zero_line;
            this.channel_serie_options.draw_line_at = item.draw_line_at;
            this.channel_serie_options.draw_line_at_value = item.draw_line_at_value;
            this.channel_serie_options.yLabel = item.yLabel;
            this.channel_serie_options.graph_type = item.graph_type;
            this.channel_serie_options.yPos = droppedYPos;
              this.submit_channel_serie_options();
              this.resize();
          }
          this.emitDiagramSettings();
          //this.dragChannel = false;
        }
    },
    props: {
        graph_buffer: {
          type: Object,
          default: null
        },
        series: {
            type: Array,
            default: null
        },
        image: {
            type: Object,
            default: null,
        },
        autoshift: {
            type: Boolean,
            default: false
        },
        fixed_height: {
            // If null, fit parent
            type: Number,
            default: null
        },
        fixed_width: {
            // If null, fit parent
            type: Number,
            default: null
        },
        paddingXLeft: {
            type: Number,
            default: 140
        },
        paddingXRight: {
            type: Number,
            default: 200
        },
        paddingY: {
            type: Number,
            default: 40
        },
        firstYAxisLeft: {
          type: Boolean,
          default: true
        },
        alarm_level_low: {
            // If null, dont show
            type: Number,
            default: null
        },
        alarm_level_high: {
            // If null, dont show
            type: Number,
            default: null
        },
        points_density_threshold: {
            type: Number,
            default: 30
        },
        area_overshoot: {
            // How far off screen should we draw points?
            // (As a fraction of the graph width)
            type: Number,
            default: 0
        },
        graph_state: {
            type: Number,
            default: 0
        },
        unique_identifier: {
            type: String
        },
        start_date: {
            type: Number,
            default: null
        },
        end_date: {
            type: Number,
            default: null
        },
        report_type: {
            type: Number,
            default: null
        },
        watching: {
            default: null
        },
        watching2: {
            default: null
        },
        thick: {
            type: Boolean,
            default: false
        },
        requested_zoom_interval: {
            type: Object,
            default: null
        },
        disable_zoom: {
            type: Boolean,
            default: false
        },
        show_channel_controls: {
            type: Boolean,
            default: false
        },
        is_loading_data: {
            type: Boolean,
            default: false
        },
        loading_progress: {
            type: String,
            default: ""
        },
        has_hold_values: {
            type: Boolean,
            default: false
        },
        has_fill_channels: {
            type: Boolean,
            default: false
        },
        has_select_nodes: {
            type: Boolean,
            default: false
        },
        select_nodes_text:{
            type: String,
            default:"Select values from tree",
        },
        done_select_nodes_text:{
            type: String,
            default:"Done select values from tree",
        },
        select_mode: {
            type: Boolean,
            default: false
        },
        zone:{
          type: Object,
          default: null
        },
        controller:{
          type: Object,
          default: null
        },        
        nodes:{
          type: Array,
          default: null
        },
        default_graph_description: {
          type: String,
          default: ""          
        },
        local_storage_id: {
          type: String,
          default: ""          
        },
        diagram: {
          type: Object,
          default: null,
        },
    },
    computed: {
          bothArraysPopulated() {
          // Check if channel_series has data
          const channelOk = this.channel_series && this.channel_series.length > 0;

          // Check if diagram.diagramSettings.series has data
          const diagramOk =
            this.diagram && 
            this.diagram.diagramSettings &&
            this.diagram.diagramSettings.series &&
            this.diagram.diagramSettings.series.length > 0;

          console.log('[bothArraysPopulated] channelOk=', channelOk, 'diagramOk=', diagramOk)
          return channelOk && diagramOk;
        },
        loading_data() {
          return this.is_loading_data;
        },
        get_series() {
            return this.series;
        },

        fill_channels_title() {
          return this.channel_series.length ? this.$txt.clear_channels : this.$txt.fill_channels;
        },
        available_graph_settings() {
            if (this.$app.graph_props == null) {
              return [];
            }
            else if ((this.zone == null) && (this.controller == null)) {
              // Project level. Neither controller nor zone selected
              return this.$app.graph_props.filter(s => (s.controllerId == null) && (s.zoneId == null));
            }
            else if (this.zone == null) {
               return this.$app.graph_props.filter(s => (s.controllerId == this.controller.id));
            }
            else {
              return this.$app.graph_props.filter(s => (s.zoneId == this.zone.id));              
            }
        },
        any_graph_setting_selected() {
            if (this.selected_graph_setting == null)
                return false;
            else if (this.selected_graph_setting == "")
                return false;
            else
                return true;
        },
        canvas_id() {
            return `${this.unique_identifier}_canvas`;
        },
        select_deselect_message() {
            return this.$txt.show_all;
        }
    },
    watch: {
    bothArraysPopulated(newVal) {
      if (newVal && !this.hasDoneInitialLoad) {
        // only run once
        this.hasDoneInitialLoad = true;
        
        // call your method that merges diagram settings into channel_series
        this.load_graph_settings_from_saved_report_diagram();
      }
    },
      'graph_buffer.hidden_series': function() {
          this.resize();
      },
        start_date() {
            this.on_selection_changed();
        },
        end_date() {
            this.on_selection_changed();
        },
        selected_graph_setting() {
            if (this.settings_loaded_from_report) {
              return;
            }
            else if (this.selected_graph_setting == null) {
              localStorage.setItem(this.localStorageId, this.getNameOfGraphProps(this.selected_graph_setting));
            }
            else {
              if (this.channel_series.length > 0) {
                this.load_graph_settings(this.selected_graph_setting);
              }
              console.log("Storing:",this.getNameOfGraphProps(this.selected_graph_setting));
              localStorage.setItem(this.localStorageId, this.getNameOfGraphProps(this.selected_graph_setting));
            }
        },
        selected_save_graph_settings_as() {
            this.save_graph_settings_as_name 
                    = this.getNameOfGraphProps(this.selected_save_graph_settings_as);
        },

        requested_zoom_interval() {
            if (this.requested_zoom_interval != null) {
                this.set_displayed_area_to_dates(this.requested_zoom_interval.from.getTime(), this.requested_zoom_interval.to.getTime());
                this.resize();
                this.on_displayed_area_changed(true);
            }
        },
   /*     watching() {
            this.on_selection_changed();
        },
        watching2() {
            this.resize();
        }, */
        graph_state() {
            this.resize();
        },
        show_channel_control_panel() {
            this.resize();
        },
        show_legend() {
            this.resize();
        }
    }
};
</script>

<style lang="css" scoped></style>
